<template>
  <v-navigation-drawer
    color="light-green lighten-5"
    id="drawer"
    app
    mini-variants
    v-model="drawer"
    :mini-variant.sync="mini"
    :dark="$vuetify.dark"
  >
    <v-toolbar color="cyan lighten-5" class="text-center ma-0 px-0 logo">
      <v-spacer></v-spacer>
      <img
        v-if="!mini"
        src="../assets/minedu_h.png"
        height="60px"
        to="/"
        class="logo px-0 mx-0"
      />
      <img
        v-else
        src="../assets/minedu_v.png"
        height="55px"
        to="/"
        class="logo px-0 mx-0"
      />
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-list nav dense link>
      <v-subheader>
        <span v-show="!mini">Menú</span>
        <v-spacer></v-spacer>
        <v-icon @click.stop="draweMini" color="cyan">{{ icon_mini }}</v-icon>
      </v-subheader>
      <div v-for="(link, i) in menus" :key="i">
        <v-list-item
          v-if="!link.submenu"
          :to="link.path"
          active-class="highlighted"
          exact
        >
          <v-list-item-icon>
            <v-icon>{{ link.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title v-text="link.text" />
        </v-list-item>

        <v-list-group
          v-else
          :key="link.text"
          no-action
          :prepend-icon="link.icon"
          :value="false"
        >
          <template v-slot:activator>
            <v-list-item-title>{{ link.text }}</v-list-item-title>
          </template>

          <v-list-item
            v-for="sublink in link.submenu"
            :to="sublink.path"
            :key="sublink.text"
            active-class="grey lighten-3"
            exact
          >
            <!-- <v-list-item-icon>
              <v-icon>{{ sublink.icon }}</v-icon>
            </v-list-item-icon>-->
            <v-list-item-title>{{ sublink.text }}</v-list-item-title>
          </v-list-item>
        </v-list-group>
      </div>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import Storage from "../utils/storage";
export default {
  data: () => ({
    mini: false,
    icon_mini: "mdi-transfer-left",
    drawer: true,
    menus: [],
  }),
  created() {
    window.getApp.$on("DRAWER_TOGGLE", () => {
      this.drawer = !this.drawer;
    });
  },
  mounted() {
    this.menus = Storage.getMenu();
  },
  methods: {
    draweMini() {
      this.mini = !this.mini;
      if (this.mini) {
        this.icon_mini = "mdi-transfer-right";
      } else {
        this.icon_mini = "mdi-transfer-left";
      }
    },
  },
};
</script>
<style lang="css">
.logo .v-toolbar__content,
.v-toolbar__extension {
  padding: 4px 0px;
}
</style>
