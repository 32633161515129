import Vue from "vue";
import Vuex from "vuex";
import Util from "../utils/storage";
import Service from "../services/request";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    status: "",
    token: sessionStorage.getItem("token") || "",
    snackbar: {
      visible: false,
      color: "success",
      text: "",
      timeout: 2500,
      multiline: false,
    },
  },
  mutations: {
    authSuccess(state, res) {
      state.token = res.token;
      state.status = "success";
      Util.setToken(res.token);
      Util.setUser(res);
      Util.setMenu(res.menu);
    },
    authError(state) {
      state.token = "";
      state.status = "error";
      Util.removeToken();
      Util.removeUser();
      Util.removeMenu();
    },
    authLogout(state) {
      state.token = "";
      state.status = "";
      Util.removeToken();
      Util.removeUser();
      Util.removeMenu();
    },
    openToast(state, payload) {
      state.snackbar.text = payload.text;
      state.snackbar.multiline = payload.text
        ? payload.text.length > 50
        : false;
      if (payload.multiline) {
        state.snackbar.multiline = payload.multiline;
      }
      if (payload.color) {
        state.snackbar.color = payload.color;
      }
      if (payload.timeout) {
        state.snackbar.timeout = payload.timeout;
      }
      state.snackbar.visible = true;
    },
    closeToast(state) {
      state.snackbar.visible = false;
      state.snackbar.multiline = false;
      state.snackbar.text = null;
    },
  },
  actions: {
    login({ commit }, data) {
      return new Promise((resolve, reject) => {
        Service.svcSignIn(data)
          .then((response) => {
            if(response.status === 'success')
            {
              commit("authSuccess", response);
              resolve({ status: response.status, message: response.message });
            }
            else
              reject({ status: response.status, message: response.message });

          })
          .catch((err) => {
            commit("authError", "Error en autenticación");
            reject({ status: err.status, message: err.message });
          });
      });
    },
    logout({ commit }) {
      // eslint-disable-next-line
      return new Promise((resolve, reject) => {
        commit("authLogout");
        resolve();
      });
    },
    toast(context, payload) {
      context.commit("openToast", payload);
    },
    closeToast(context) {
      context.commit("closeToast");
    },
  },
  getters: {
    isAuthenticated: (state) => !!state.token,
    authStatus: (state) => state.status,
    getSnackBar: (state) => state.snackbar,
  },
});
