export default {
  strencdec(str, k) {
    let str_encode = "";
    for (let index = 0; index < str.length; index++) {
      var a = str.charCodeAt(index);
      var b = a ^ k;
      str_encode = str_encode + String.fromCharCode(b);
    }
    return str_encode;
  },
  setToken(token) {
    return sessionStorage.setItem("token", token);
  },
  getToken() {
    return sessionStorage.getItem("token") || "null";
  },
  removeToken() {
    return sessionStorage.removeItem("token");
  },
  setUser(user) {
    return sessionStorage.setItem(
      "hruwq",
      this.strencdec(
        JSON.stringify({
          rid: user.iro,
          uid: user.ius,
          ies: user.ies,
          name: user.name,
          rol: user.rol,
        }),
        5
      )
    );
  },
  getUser() {
    return sessionStorage.getItem("hruwq")
      ? JSON.parse(this.strencdec(sessionStorage.getItem("hruwq"), 5))
      : null;
  },
  removeUser() {
    return sessionStorage.removeItem("hruwq");
  },
  setMenu(menu) {
    return sessionStorage.setItem(
      "friwo",
      this.strencdec(JSON.stringify(menu), 3)
    );
  },
  getMenu() {
    return sessionStorage.getItem("friwo")
      ? JSON.parse(this.strencdec(sessionStorage.getItem("friwo"), 3))
      : null;
  },
  removeMenu() {
    return sessionStorage.removeItem("friwo");
  },
};
