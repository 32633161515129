import endpoint from "../utils/environment";
// import Storage from "../utils/storage";
import axios from "axios";

/* axios.defaults.headers = {
  Accept: "application/json",
  Authorization: "Bearer " + Storage.getToken(),
}; */
const axio = axios.create({
  baseURL: endpoint.getServe(),
  //timeout: 2000,
});

/* axio.interceptors.response.use(
  (response) => {
    console.log(1111111, response);
    if ([200, 201, 204].includes(response.status)) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  (error) => {
    if (error.response.status) {
      console.log(error.response.status);
      switch (error.response.status) {
        case 400:
          //do something
          break;

        case 401:
          alert("session expired");
          break;
        case 403:
          // router.replace({
          //   path: "/signin",
          //   query: { redirect: router.currentRoute.fullPath },
          // });
          break;
        case 404:
          alert("page not exist");
          break;
        case 502:
        // setTimeout(() => {
        //     router.replace({
        //       path: "/signin",
        //       query: {
        //         redirect: router.currentRoute.fullPath,
        //       },
        //     });
        //   }, 1000);
      }
      return Promise.reject(error.response);
    }
  }
); */

export default {
  svcSignIn(payload) {
    return new Promise((resolve, reject) => {
      axio
        .post("usuario/auth", payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getStatus() {
    return [
      { id: true, description: "Activo" },
      { id: false, description: "No activo" },
    ];
  },
  getYears() {
    let years = [],
      min = 2015,
      max = new Date().getFullYear();
    for (let i = max; i >= min; i--) {
      years.push(i);
    }
    return years;
  },
  // MANAGEMENT
  async normalCRUD(route, method, payload) {
    let result = null;
    try {
      switch (method) {
        case "post":
          result = await axio.post(route, payload, endpoint.getHeader());
          break;
        case "put":
          result = await axio.put(
            `${route}/${payload.id}`,
            payload,
            endpoint.getHeader()
          );
          break;
        case "delete":
          result = await axio.delete(
            `${route}/${payload.id}`,
            endpoint.getHeader()
          );
          break;
        default:
          result = await axio.get(route, endpoint.getHeader());
          break;
      }
      return Promise.resolve(result);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async fileCRUD(route, method, payload, id) {
    let result = null;
    try {
      switch (method) {
        case "post":
          result = await axio.post(route, payload, endpoint.getFileHeader());
          break;
        case "put":
          result = await axio.put(
            `${route}/${id}`,
            payload,
            endpoint.getFileHeader()
          );
          break;
        default:
          break;
      }
      return Promise.resolve(result);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  svcTypeInformation(method, payload = null) {
    const route = "api/gerencia/tipo/informacion";
    return this.normalCRUD(route, method, payload);
  },
  svcStatusInformation(method, payload = null) {
    const route = "api/gerencia/estado/informacion";
    return this.normalCRUD(route, method, payload);
  },
  svcActivityRegisterParameter(method, payload = null) {
    const route = "api/gerencia/actividad/parametro";
    return this.normalCRUD(route, method, payload);
  },
  svcActivity(method, payload = null, file = null) {
    let route = "api/gerencia/actividad";
    if (["post", "put"].includes(method) && file) {
      route = "api/gerencia/actividadf";
      let payloadf = new FormData();
      payloadf.append("tipo_informacion_id", payload.tipo_informacion_id);
      payloadf.append("roles_id", JSON.stringify(payload.roles_id));
      payloadf.append("descripcion", payload.descripcion);
      payloadf.append("fecha_limite", payload.fecha_limite);
      payloadf.append("location", payload.location);
      payloadf.append("estado", payload.estado);
      payloadf.append("plantilla", payload.plantilla);

      return this.fileCRUD(route, method, payloadf, payload.id);
    }
    return this.normalCRUD(route, method, payload);
  },
  svcActivityPaginate(method, options) {
    const route = "api/gerencia/actividad/lista";
    return this.normalCRUD(route, method, options);
  },
  svcChangeActivity(method, id) {
    const route = "api/gerencia/actividad/cambiar";
    return this.normalCRUD(route, method, id);
  },
  svcActivityByRole(method, options) {
    const route = "api/gerencia/actividad/por/rol";
    return this.normalCRUD(route, method, options);
  },
  svcInformationParams(method, payload = null) {
    const route = "api/gerencia/informacion/parametro";
    return this.normalCRUD(route, method, payload);
  },
  svcInformation(method, payload = null, file = null) {
    const route = "api/gerencia/informacion";
    if (["post", "put"].includes(method) && file) {
      let payloadf = new FormData();
      payloadf.append("institucion_id", payload.institucion_id);
      payloadf.append("actividad_id", payload.actividad_id);
      payloadf.append("descripcion", payload.descripcion);
      payloadf.append("location", payload.location);
      payloadf.append("documento", payload.documento);
      return this.fileCRUD(route, method, payloadf, payload.id);
    }
    return this.normalCRUD(route, method, payload);
  },
  svcInformationPaginate(method, options) {
    const route = "api/gerencia/informacion/lista";
    return this.normalCRUD(route, method, options);
  },
  svcInformationByActivity(method, options) {
    const route = "api/gerencia/informacion/actividad";
    return this.normalCRUD(route, method, options);
  },
  svcInformationReport(method, options) {
    const route = "api/gerencia/informacion/reporte";
    return this.normalCRUD(route, method, options);
  },
  svcActivityInformationReport(method, options) {
    const route = "api/gerencia/actividad/informacion/reporte";
    return this.normalCRUD(route, method, options);
  },
  svcDepartmentParameter(method, payload = null) {
    const route = "parametro/departamento";
    return this.normalCRUD(route, method, payload);
  },
  svcInstitutionParameter(method, payload = null) {
    const route = "api/sifm/parametro/institucion";
    return this.normalCRUD(route, method, payload);
  },
  svcSpecialityParameter(method, payload = null) {
    const route = "api/sifm/parametro/especialidad";
    return this.normalCRUD(route, method, payload);
  },
  svcInstitution(method, payload = null) {
    const route = "reporte/institucion";
    return this.normalCRUD(route, method, payload);
  },
  svcStatisticDepartment(method, payload = null) {
    const route = "api/sifm/estadistico/departamento";
    return this.normalCRUD(route, method, payload);
  },
  svcStatisticInstitution(method, payload = null) {
    const route = "api/sifm/estadistico/institucion";
    return this.normalCRUD(route, method, payload);
  },
  svcInstitutionById(method, payload = null) {
    const route = "reporte/institucion/" + payload.institution_id;
    return this.normalCRUD(route, method, payload);
  },
  svcSpecialitiesByInstitution(method, payload = null) {
    const route = "reporte/especialidad/institucion";
    return this.normalCRUD(route, method, payload);
  },

  svcQuantityTeacher(method, payload = null) {
    const route = "api/sifm/cantidad/docente";
    return this.normalCRUD(route, method, payload);
  },
  svcQuantityStudent(method, payload = null) {
    const route = "api/sifm/cantidad/estudiante";
    return this.normalCRUD(route, method, payload);
  },

  svcExecutiveStaff(method, payload = null) {
    const route = "api/sifm/reporte/personal/directivo";
    return this.normalCRUD(route, method, payload);
  },
  svcExecutiveStaffGeneral(method, payload = null) {
    const route = "api/sifm/pdf/personal/directivo/general";
    return this.normalCRUD(route, method, payload);
  },
  svcAdministrativeStaff(method, payload = null) {
    const route = "api/sifm/reporte/personal/administrativo";
    return this.normalCRUD(route, method, payload);
  },
  svcAdministrativeStaffGeneral(method, payload = null) {
    const route = "api/sifm/pdf/personal/administrativo/general";
    return this.normalCRUD(route, method, payload);
  },
  svcServiceStaff(method, payload = null) {
    const route = "api/sifm/reporte/personal/servicio";
    return this.normalCRUD(route, method, payload);
  },
  svcServiceStaffGeneral(method, payload = null) {
    const route = "api/sifm/pdf/personal/servicio/general";
    return this.normalCRUD(route, method, payload);
  },
  svcTeacherStaff(method, payload = null) {
    const route = "api/sifm/reporte/personal/docente";
    return this.normalCRUD(route, method, payload);
  },
  svcTeacherStaffGeneral(method, payload = null) {
    const route = "api/sifm/pdf/personal/docente/general";
    return this.normalCRUD(route, method, payload);
  },
  svcTeacherSchedule(method, payload = null) {
    const route = "api/sifm/reporte/horario/docente";
    return this.normalCRUD(route, method, payload);
  },
  svcStudentStaff(method, payload = null) {
    const route = "api/sifm/reporte/personal/estudiante";
    return this.normalCRUD(route, method, payload);
  },
  svcPeriodYears(method, payload = null) {
    const route = "api/sifm/periodo/gestion";
    return this.normalCRUD(route, method, payload);
  },
  svcPersonById(method, payload = null) {
    const route = "api/sifm/detalle/persona/" + payload.person_code;
    return this.normalCRUD(route, method, payload);
  },
};
