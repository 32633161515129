import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import Highcharts from "highcharts";
// import highchartsMore from "highcharts/highcharts-more";
import exportingInit from "highcharts/modules/exporting";
import HighchartsVue from "highcharts-vue";
import router from "./router";
import store from "./store";

exportingInit(Highcharts);
// highchartsMore(Highcharts);
Vue.use(HighchartsVue);

Vue.mixin({
  methods: {
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    isText: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode == 32 ||
        charCode == 209 ||
        charCode == 241 ||
        (65 <= charCode && charCode <= 90) ||
        (97 <= charCode && charCode <= 122)
      ) {
        return true;
      } else {
        evt.preventDefault();
        return false;
      }
    },
  },
});

Vue.filter("formatdate", function (value) {
  if (!value) return "";
  return value.substring(0, 10).split("-").reverse().join("/");
});

Vue.filter("formatfulldate", function (value) {
  if (!value) return "";
  return `${value
    .substring(0, 10)
    .split("-")
    .reverse()
    .join("/")} ${value.substring(11, 19)}`;
});

Vue.config.productionTip = false;
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
