import Vue from "vue";
import Router from "vue-router";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import store from "../store";

let paths = [
  {
    path: "*",
    meta: {
      public: true,
    },
    redirect: {
      path: "/404",
    },
  },
  {
    path: "/",
    meta: {
      public: true,
      title: "Autenticación",
    },
    name: "signin",
    component: () => import(`@/views/Singin.vue`),
  },
  {
    path: "/404",
    meta: {
      public: false,
    },
    name: "NotFound",
    component: () => import(`@/views/NotFound.vue`),
  },
  {
    path: "/dashboard",
    meta: {
      public: false,
      requiresAuth: true,
    },
    name: "dashboard",
    component: () => import(`@/views/Dashboard.vue`),
  },
  {
    path: "/perfil",
    meta: {
      public: false,
      requiresAuth: true,
    },
    name: "profile",
    component: () => import(`@/views/Perfil.vue`),
  },
  {
    path: "/type/information",
    meta: {
      public: false,
      requiresAuth: true,
    },
    name: "type-information",
    component: () => import(`@/views/management/TypeInformation.vue`),
  },
  {
    path: "/status/information",
    meta: {
      public: false,
      requiresAuth: true,
    },
    name: "status-information",
    component: () => import(`@/views/management/StatusInformation.vue`),
  },
  {
    path: "/activities",
    meta: {
      public: false,
      requiresAuth: true,
    },
    name: "activities",
    component: () => import(`@/views/management/Activity.vue`),
  },
  {
    path: "/information/received",
    meta: {
      public: false,
      requiresAuth: true,
    },
    name: "information-received",
    component: () => import(`@/views/management/InformationReceived.vue`),
  },
  {
    path: "/information",
    meta: {
      public: false,
      requiresAuth: true,
    },
    name: "information-sent",
    component: () => import(`@/views/management/InformationSent.vue`),
  },
  {
    path: "/activity/report",
    meta: {
      public: false,
      requiresAuth: true,
    },
    name: "activity-report",
    component: () => import(`@/views/management/ActivityReport.vue`),
  },
  // Report ESFM
  {
    path: "/statistics/department",
    meta: {
      public: false,
      requiresAuth: true,
    },
    name: "statistics-department",
    component: () => import(`@/views/sifm/DashboardDepartment.vue`),
  },
  {
    path: "/statistics/institution/admin",
    meta: {
      public: false,
      requiresAuth: true,
    },
    name: "statistics-institution-admin",
    component: () => import(`@/views/sifm/DashboardInstitutionAdmin.vue`),
  },
  {
    path: "/statistics/institution",
    meta: {
      public: false,
      requiresAuth: true,
    },
    name: "statistics-institution",
    component: () => import(`@/views/sifm/DashboardInstitution.vue`),
  },
  {
    path: "/institution/report",
    meta: {
      public: false,
      requiresAuth: true,
    },
    name: "institution-report",
    component: () => import(`@/views/sifm/Institution.vue`),
  },
  {
    path: "/executive/staff/report",
    meta: {
      public: false,
      requiresAuth: true,
    },
    name: "executive-staff-report",
    component: () => import(`@/views/sifm/ExecutiveStaff.vue`),
  },
  {
    path: "/administrative/staff/report",
    meta: {
      public: false,
      requiresAuth: true,
    },
    name: "administrative-staff-report",
    component: () => import(`@/views/sifm/AdministrativeStaff.vue`),
  },
  {
    path: "/service/staff/report",
    meta: {
      public: false,
      requiresAuth: true,
    },
    name: "service-staff-report",
    component: () => import(`@/views/sifm/ServiceStaff.vue`),
  },
  {
    path: "/teacher/staff/report",
    meta: {
      public: false,
      requiresAuth: true,
    },
    name: "teacher-staff-report",
    component: () => import(`@/views/sifm/TeacherStaffAdmin.vue`),
  },
  {
    path: "/teacher/report",
    meta: {
      public: false,
      requiresAuth: true,
    },
    name: "teacher-report",
    component: () => import(`@/views/sifm/TeacherStaff.vue`),
  },
  {
    path: "/student/staff/report",
    meta: {
      public: false,
      requiresAuth: true,
    },
    name: "student-staff-report",
    component: () => import(`@/views/sifm/StudentStaffAdmin.vue`),
  },
  {
    path: "/student/report",
    meta: {
      public: false,
      requiresAuth: true,
    },
    name: "student-report",
    component: () => import(`@/views/sifm/StudentStaff.vue`),
  },
];
Vue.use(Router);
const router = new Router({
  base: "/",
  mode: "hash",
  linkActiveClass: "active",
  routes: paths,
});

router.afterEach((to) => {
  NProgress.done();
  const DEFAULT_TITLE = "Sistema EFI";
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
  });
});
router.beforeEach((to, from, next) => {
  NProgress.start();
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated) {
      next();
      return;
    }
    next("/");
  } else {
    next();
  }
});

export default router;
